<template>
  <div>
    <!--单位主页 -->
    <el-card class="box-card xtxiaox">
      <el-tag effect="dark">系统消息</el-tag>
      <i class="el-icon-message-solid" style="margin: 0 4px 0 8px"></i>
      欢迎您访问赣州经开区人才信息管理系统
    </el-card>
    <div class="hr-10"></div>
    <el-button @click="getEnterpriseAuthentication" v-show="isMobile"
      >修改信息</el-button
    >
    <div class="hr-10"></div>
    <el-card class="box-card" v-model="tableData">
      <el-row>
        <el-col :span="8" class="flususeskk">
          <div class="useskk">
            <img src="@/assets/user.jpg" alt />
            <div class="xinxss">
              <div>
                {{ tableData.name }}
                <el-tag
                  size="mini"
                  type="success"
                  v-model="status"
                  v-if="status === 21"
                  >已认证</el-tag
                >
                <el-tag
                  size="mini"
                  type="danger"
                  v-model="status"
                  v-else-if="status === 20"
                  >已驳回</el-tag
                >
                <el-tag size="mini" type="info" v-model="status" v-else
                  >未认证</el-tag
                >
              </div>
              <div>
                <el-button
                  type="success"
                  icon="el-icon-edit"
                  v-model="status"
                  @click="clickMenu()"
                  v-if="status === 11"
                  >修改认证</el-button
                >
                <el-button
                  type="success"
                  v-model="status"
                  @click="requestReject"
                  v-else-if="status === 20"
                  >点击查看驳回理由</el-button
                >
                <el-button
                  :disabled="status === 21"
                  type="primary"
                  icon="el-icon-edit"
                  v-else
                  @click="clickMenu()"
                  >单位认证</el-button
                >
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="16" class="flususeskk">
          <div id="steps">
            <el-steps :active="returnStatus" align-center>
              <el-step title="认证提交" icon="el-icon-s-claim"></el-step>
              <el-step title="审核通过" icon="el-icon-s-custom"></el-step>
            </el-steps>
          </div>
        </el-col>
      </el-row>
    </el-card>

    <div style="color: red;margin-top: 24px;float: left">
      * 若单位变更了收款信息请及时在认证页面修改并重新提交审核！
    </div>

    <el-dialog
      title="提示"
      :visible.sync="dialog"
      width="480px"
      :showClose="showClo"
      style="margin-top: 4%"
    >
      <div style="font-size: 16px">
        您有
        <span style="color: #E6A23C;font-weight: bolder">
          {{ examineNumber }}
        </span>
        条人才认证信息待处理，请尽早前往处理！
      </div>
      <div style="margin-top: 8px;font-size: 16px">
        您有
        <span style="color: #E6A23C;font-weight: bolder">
          {{ declareDateNumber }}
        </span>
        条人才申报申请待处理，请尽早前往处理！
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialog = !dialog">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="驳回理由" :visible.sync="dialogVisible" width="520px">
      <el-input type="textarea" :rows="5" v-model="memo" readonly=""></el-input>
      <div class="hr-20"></div>
      <el-button @click="dialogVisible = false" class="mr-4">关闭</el-button>
      <el-button @click="getEnterpriseAuthentication">重新认证</el-button>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobile: false,
      dialog: false,
      dialogVisible: false,
      memo: "",
      showClo: false,
      id: sessionStorage.getItem("ID"),
      createLoading: false,
      tableData: [],
      status: 0,
      state: "",
      // size: 0,
      returnStatus: 0,
      tableNewData: [],
      examineNumber: 0,
      tableDeclareData: [],
      declareDateNumber: 0,
      number: [],
      newId: this.$route.query.id,
    };
  },
  created() {
    this.getData();
    this.getNewDate();
    this.getDeclareDate();
  },
  mounted() {
    if (this.commonFunction._isMobile()) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  methods: {
    getData() {
      this.$axios
        .get("/enterprise?id=" + this.id)
        .then((response) => {
          this.tableData = response.data.data;
          this.status = response.data.data.status;
          this.state = response.data.data.status;
          if (this.state === 2) {
            this.returnStatus = 1;
          } else if (this.state === 20) {
            this.returnStatus = 1;
          } else if (this.state === 21) {
            this.returnStatus = 2;
          } else {
            this.returnStatus = 0;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getNewDate() {
      let self = this;
      this.$axios
        .post("/talent/condition", {
          enterpriseId: sessionStorage.getItem("ID"),
          statuses: [2],
        })
        .then(function(res) {
          if (res.data.data.records.length > 0) {
            if (self.newId === "enterprise") {
              self.dialog = true;
              self.examineNumber = res.data.data.records.length;
            }
          }
        });
    },
    //  驳回
    requestReject() {
      this.dialogVisible = true;
      this.$axios
        .get("/enterprise/detail?id=" + sessionStorage.getItem("ID"))
        .then((response) => {
          if (response.data.status === 200) {
            this.memo = response.data.data.memo;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getDeclareDate() {
      this.$axios
        .get("/affair/enterprise/page", {
          params: {
            enterpriseId: this.id,
          },
        })
        .then((response) => {
          console.log(response);
          this.tableDeclareData = response.data.data.records;
          for (let i = 0; i < response.data.data.records.length; i++) {
            if (
              this.tableDeclareData[i].currentStatus === 0 &&
              this.tableDeclareData[i].complete === 0
            ) {
              this.number.push(this.tableDeclareData[i]);
              if (this.newId === "enterprise") {
                this.dialog = true;
              }
            }
          }
          this.declareDateNumber = this.number.length;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    clickMenu() {
      this.$store.commit("selectMenu", {
        icon: "el-icon-setting",
        label: "认证管理",
        path: "/qyprocessing",
      });
      this.$router.push("/qyprocessing");
    },
    getEnterpriseAuthentication() {
      this.$store.commit("selectMenu", {
        icon: "el-icon-setting",
        label: "认证管理",
        path: "/qyprocessing",
      });
      this.$router.push("/qyprocessing");
    },
  },
};
</script>

<style lang="less" scoped>
.useskk {
  display: flex;
  justify-content: space-between;

  .xinxss {
    width: 100%;
    line-height: 40px;
    text-align: center;
    font-size: 24px;
    margin: auto;

    span {
      background: #eff0f4;
      /*padding: 5px 10px;*/
      border-radius: 12px;
      margin: 0 0 0 5px;
      font-size: 12px;
    }
    button {
      margin-top: 24px;
    }

    .renzhen {
      a {
        text-decoration: none;
        color: #fff;
      }
    }
  }
}
#steps {
  margin: auto;
  position: relative;
  transform: translateY(70%);
  font-size: 32px;
}
.xtxiaox {
  text-align: left;
}
@import "../../../styles/common_qyrc.less";
</style>
